<template>
  <div class="animated fadeIn">
    <b-container>
      <div class="mt-3 mb-3">
        <b-link :to="{ path: '/create-loan' }">Зээл бүртгэх</b-link>
      </div>
      <hr />

      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="createPayRequest()">
        <b-form-group id="input-group-customer" label="Хэрэглэгч сонгох:">
          <multiselect
            v-model="form.customer"
            :loading="isLoadingCustomer"
            :options="customers"
            :searchable="true"
            :custom-label="customerName"
            :multiple="false"
            placeholder="Хэрэглэгч сонгох"
            label="firstname"
            track-by="id"
            @search-change="asyncFind"
          >
            <template slot="singleLabel" slot-scope="props">
              {{
                props.option.lastname +
                  " " +
                  props.option.firstname +
                  " (" +
                  props.option.register +
                  " | " +
                  props.option.phone +
                  ")"
              }}
            </template>
            <template slot="option" slot-scope="props">
              {{
                props.option.lastname +
                  " " +
                  props.option.firstname +
                  " (" +
                  props.option.register +
                  " | " +
                  props.option.phone +
                  ")"
              }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group id="input-group-4">
          <b-form-checkbox value="true" v-model="form.isClose" switch
            >Хаах эсэх</b-form-checkbox
          >
        </b-form-group>

        <b-form-group
          id="input-group-customer"
          label="Хаах данс сонгох:"
          v-show="form.isClose && form.customer"
        >
          <multiselect
            v-model="form.accounts"
            :loading="isLoadingAccounts"
            :options="accounts"
            :searchable="true"
            :multiple="true"
            placeholder="Хаах данс сонгох"
            label="accountNumber"
            :hide-selected="true"
            :close-on-select="false"
            track-by="id"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag">
                <span>
                  <small>{{ option.accountNumber }}:</small> &nbsp;
                  <strong>{{
                    option.loanBalance + "₮/" + option.loanAmount + "₮"
                  }}</strong>
                  {{ " (" + option.loanDate + ")" }}
                </span>
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keypress.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon"
                ></i>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <span>
                <small>{{ props.option.accountNumber }}:</small> &nbsp;
                <strong class="text-danger">{{
                  props.option.loanBalance +
                    "₮/" +
                    props.option.loanAmount +
                    "₮"
                }}</strong>
                {{ " (" + props.option.loanDate + ")" }}
              </span>
            </template>
          </multiselect>
        </b-form-group>

        <!-- <b-form-group id="input-group-payDescription" label="Дүн" label-for="input-payDescription">
                <b-form-input id="input-payDescription" v-model="form.payAmount" required placeholder="Дүн"></b-form-input>
            </b-form-group>  -->
        <b-button type="submit" variant="success">Код авах</b-button>
      </b-form>

      <b-form v-if="payCode != ''" @submit="sendData()">
        <b-form-group
          id="input-group-payDescription"
          label="Дүн"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="amount"
            required
            placeholder="Дүн"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Огноо"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="payDate"
            required
            placeholder="Огноо"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Утга"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="payCode"
            required
            placeholder="Утга"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="success">Илгээх</b-button>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "no-dtl",
  data: function() {
    return {
      isLoading: false,
      isLoadingCustomer: false,
      isLoadingAccounts: false,
      form: {
        amount: "",
        transactionDate: "",
        payDescription: "",
        payBank: "",
        isClose: false,
        customer: null,
        description: "",
        isCreate: true,
        accounts: [],
        payAmount: "",
      },
      show: true,
      customers: [],
      accounts: [],
      payCode: "",
      amount: "",
      payDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  },
  watch: {
    "form.isClose": function(newVal, oldVal) {
      this.loadAccounts();
    },
    "form.customer": function(newVal, oldVal) {
      this.loadAccounts();
    },
    deep: true,
  },
  methods: {
    asyncFind(query) {
      this.isLoadingCustomer = false;
      this.$http
        .get(this.$config.API_URL + "TestWebService/get_customers", {
          params: {
            page: 1,
            per_page: 0,
            orderDirection: "ASC",
            orderBy: "fistname",
            search: query,
            columns: "firstname,lastname,phone,register",
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoadingCustomer = false;
            this.customers = response.body.data;
          },
          (response) => {
            this.isLoadingCustomer = false;
          }
        );
    },
    loadAccounts: function() {
      if (this.$data.form.isClose && this.$data.form.customer) {
        console.log({
              id: this.form.customer.id,
              date: moment().format('YYYY-MM-DD')
            })
        this.isLoadingAccounts = true;
        this.$http
          .get(this.$config.API_URL + "TestWebService/get_loan_active_account", {
            params: {
              id: this.form.customer.id,
              date: moment().format('YYYY-MM-DD')
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          })
          .then(
            (response) => {
              console.log(response)
              this.isLoadingAccounts = false;
              this.accounts = response.body.data;
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    customerName({ lastname, firstname, register, phone }) {
      return `${lastname} — ${firstname} — ${register} — ${phone}`;
    },
    createPayRequest: function() {
      var ids = [];
      for (var i in this.$data.form.accounts) {
        ids.push(this.$data.form.accounts[i].id);
      }

      this.$http
        .post(
          this.$config.API_URL + "TestWebService/create_pay_request",
          {
            customerId: this.$data.form.customer.id,
            isClosed: this.$data.form.isClose,
            payAmount: "11",
            ids: JSON.stringify(ids),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoadingCustomer = false;
            //console.log(response)
            if (response.body.success) {
              this.$data.payCode = response.body.code;
            } else {
              this.showToast("Алдаа", "Алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoadingCustomer = false;
          }
        );
    },
    sendData: function() {
      this.$data.sform = {
        Account: "460029584",
        Amount: this.$data.amount,
        Currency: "MNT",
        TXNSIGN: " ",
        JRNO: "691500016393",
        JRITEMNO: "4",
        "Available Balance": "2323.23",
        Desc: this.$data.payCode,
        Date: moment(this.$data.payDate).format("YY/MM/DD HH:mm:ss"),
      };
      this.$data.isLoading = true;
      this.$http
        .post(
          this.$config.APP_URL + "CustomerWebService/pay_loan",
          {
            response: JSON.stringify(this.$data.sform),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response)
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.$data.payCode = "";
              this.loadAccounts();
              this.$data.form.accounts = [];
            } else {
              this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
